import React, { useState, useEffect } from 'react';

import config from './config';
const API_BASE_URL = config.API_BASE_URL;


const WestAutomateOnboarding = () => {
  const [fullName, setFullName] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [validationCode, setValidationCode] = useState('');
  const [step, setStep] = useState('signup');
  const [error, setError] = useState('');
  const [testMessage, setTestMessage] = useState('');

  useEffect(() => {
    // Test connection to backend
    fetch(`${API_BASE_URL}/api/test`)
      .then(response => response.json())
      .then(data => setTestMessage(data.message))
      .catch(error => setTestMessage('Failed to connect to backend'));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${API_BASE_URL}/api/send-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fullName, whatsappNumber }),
      });

      const data = await response.json();

      if (data.success) {
        setStep('verify');
      } else {
        setError(data.message || 'Failed to send code');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to send validation code. Please try again.');
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${API_BASE_URL}/api/verify-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ whatsappNumber, validationCode }),
      });

      const data = await response.json();

      if (data.success) {
        alert('Account created successfully!');
      } else {
        setError(data.message || 'Invalid code. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setError('Failed to verify code. Please try again.');
    }
  };

  return (
    <div>
      <h1>WestAutomate WhatsApp Onboarding</h1>
      <p>Backend connection test: {testMessage}</p>
      {error && <p style={{color: 'red'}}>{error}</p>}
      {step === 'signup' ? (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Full Name"
            required
          />
          <input
            type="tel"
            value={whatsappNumber}
            onChange={(e) => setWhatsappNumber(e.target.value)}
            placeholder="WhatsApp Number"
            required
          />
          <button type="submit">Send Code</button>
        </form>
      ) : (
        <form onSubmit={handleVerify}>
          <input
            type="text"
            value={validationCode}
            onChange={(e) => setValidationCode(e.target.value)}
            placeholder="Validation Code"
            required
          />
          <button type="submit">Verify Code</button>
        </form>
      )}
    </div>
  );
};

export default WestAutomateOnboarding;