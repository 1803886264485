import React from 'react';
import WhatsAppOnboarding from './WhatsAppOnboarding';

function App() {
  return (
    <div className="App">
      <WhatsAppOnboarding />
    </div>
  );
}

export default App;