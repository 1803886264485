const config = {
    development: {
      API_BASE_URL: 'http://localhost:5000', // Local environment
    },
    production: {
      API_BASE_URL: '',  // Let Nginx handle the proxying
    },
  };
  
  export default config[process.env.NODE_ENV || 'development'];
  